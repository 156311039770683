import { motion } from "framer-motion";

export default function Maintenance() {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-900 text-center p-6">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-lg w-full border border-gray-700"
      >
        <h1 className="text-3xl font-bold text-gray-100">🚧</h1>
        <p className="text-gray-300 mt-3 text-lg">
          Currently, there are no predictions available 😞
        </p>
        <p className="text-gray-400 mt-2">
          New contests will be announced on Twitter/X. Follow Nos+ for updates!  
        </p>
        <motion.a
          href="https://twitter.com/nos_plus"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block mt-4 text-blue-400 hover:text-blue-500 text-lg font-medium transition duration-200"
          whileHover={{ scale: 1.1 }}
        >
          👉 @nos_plus
        </motion.a>
      </motion.div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Maintenance from './pages/Maintenance'; // Import the Maintenance page
import './styles/App.css';

// Import all other pages
import NFLMatchesPage from './pages/NFLMatchesPage';
import ChampionsLeagueMatchesPage from './pages/ChampionsLeagueMatchesPage';
import LeaderboardPage from './pages/LeaderboardPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ResultsPage from './pages/ResultsPage';
import AuthCallback from './pages/AuthCallback';
import UserProfilePage from './pages/UserProfilePage';
import LiveActivitiesPage from './pages/LiveActivitiesPage';
import FAQPage from './pages/FAQPage';
import TOSPage from './pages/TOSPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';

function App({ applyScrollbarStyles }) {
  const location = useLocation();
  
  // Toggle this to enable or disable maintenance mode
  const isMaintenanceMode = true; // Set to `false` when maintenance is over

  if (isMaintenanceMode) {
    return <Maintenance />;
  }

  return (
    <div>
      <Helmet>
        <meta name="description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta property="og:title" content="NOS Predictions" />
        <meta property="og:description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta property="og:image" content="https://ttg.nos.plus/banners.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={`https://ttg.nos.plus${location.pathname}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="NOS Predictions" />
        <meta name="twitter:description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta name="twitter:image" content="https://ttg.nos.plus/banners.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <title>NOS Predictions</title>
      </Helmet>

      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate replace to="/cl/Matches" />} />
        <Route path="/nfl/Matches" element={<NFLMatchesPage />} />
        <Route path="/cl/Matches" element={<ChampionsLeagueMatchesPage />} />
        <Route path="/nfl/Results" element={<ResultsPage league="nfl" />} />
        <Route path="/cl/Results" element={<ResultsPage league="cl" />} />
        <Route path="/nfl/Leaderboard" element={<LeaderboardPage league="nfl" />} />
        <Route path="/cl/Leaderboard" element={<LeaderboardPage league="cl" />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/profile/:userId" element={<UserProfilePage />} />
        <Route path="/live-activities" element={<LiveActivitiesPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/terms-of-service" element={<TOSPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
